<template>
    <div class="popup-layout">
        <div class="popup popup--6 achievementsModal">
            <button type="button" class="popup__close" @click="onClose"></button>
            <div class="achievementsModal__content">
                <p class="h3">
                    {{ achievementsTitle }}
                </p>
                <p class="boxText boxText--blue achievementsModal__subText">
                    Достигло {{ achievementsRarity }} студентов
                </p>
                <p class="achievementsModal__contentIcon">
                    <img
                        class="achievementsIcon achievementsIcon--lg"
                        alt="avatarCard2"
                        :src="achievementsIcon"
                    />
                </p>
                <p class="h4 achievementsModal__text">Вы открыли достижение:</p>
                <p class="colorGullGray achievementsModal__subText">
                    {{ achievementsDescription }}
                </p>
                <p class="achievementsModal__contentButton">
                    <Button title="круто!" :onClick="() => onClose()" />
                </p>
                <div class="achievementsModal__share">
                    <ShareBlock
                        :shareText="shareText"
                        :shareIcon="achievementsIcon"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
import ShareBlock from "@/views/components/ShareBlock/ShareBlock";
export default {
    name: "dashboardAchievementsModal",
    components: {
        Button,
        ShareBlock
    },
    props: [
        "onClose",
        "achievementsIcon",
        "achievementsRarity",
        "achievementsTitle",
        "achievementsDescription",
    ],

    computed: {
        courseName() {
            return this.$store.getters.getMember && this.$store.getters.getMember.data && this.$store.getters.getMember.data.courseName || "";
        },

        shareText() {
            return `Я получил(а) достижение "${this.achievementsTitle}" - "${this.achievementsDescription}" на курсе ${this.courseName} от Александра Герчика https://edu.gerchik.com/` || "";
        },
    },

    data() {
        return {};
    },

    methods: {},
};
</script>

<style lang="scss">
@import "dashboardAchievementsModal.scss";
</style>
