<template>
    <div class="achievementsCard" @click="onClick">
        <img
            class="achievementsIcon achievementsCard__icon"
            v-if="achievementsIcon"
            v-bind:src="achievementsIcon"
        />
        <img
            class="achievementsIcon achievementsCard__icon"
            v-if="!achievementsIcon"
            src="@/assets/img/common/achievements/achievementsLock.svg"
        />
        <div class="achievementsCard__content">
            <p :class="`boxText ${isReceived ? 'boxText--blue' : 'boxText--grey'}`">
                Достигло {{ achievementsRarity }} студентов
            </p>
            <p class="h3 achievementsCard__contentTitle">
                {{ achievementsTitle }}
            </p>
            <p class="text2 colorGray achievementsCard__contentText">
                {{ achievementsDescription }}
            </p>
        </div>
    </div>
</template>

<script>
import AchievementsLock from "@/assets/img/common/achievements/achievementsLock.svg";

export default {
    name: "AchievementsCard",
    components: {
        AchievementsLock,
    },
    props: {
        achievementsIcon: String,
        achievementsRarity: String,
        achievementsTitle: String,
        achievementsDescription: String,
        isReceived: Boolean,
        onClick: Function,
    },
};
</script>

<style lang="scss">
@import "./achievementsCard.scss";
</style>
