<template>
    <Layout>
        <PageHeader
            :items="items"
            :title="title"
            :link="`/${this.$route.params.slug}/dashboard`"
        />
        <div class="pageSection">
            <div class="pageContent pageContent--2">
                <div class="dashboardAchievements">
                    <p class="h2 colorFiord">
                        Полученные ({{ achievementsList.length }})
                    </p>
                    <div class="dashboardAchievements__content">
                        <div
                            class="dashboardAchievements__contentItem"
                            v-for="(item, index) in achievementsList"
                            :key="index"
                        >
                            <AchievementsCard
                                :achievementsIcon="
                                    `${$oldClientTestUrl.slice(0, -1) + item.icon}`
                                "
                                :achievementsTitle="item.name"
                                :achievementsDescription="item.description"
                                :isReceived="item.isActive"
                                :achievementsRarity="item.howManyHaveAchievement"
                                :onClick="() => onOpenModal(item._id)"
                            />
                        </div>
                        <DashboardAchievementsModal
                            v-if="isVisibleModal"
                            :achievementsIcon="
                                `${$oldClientTestUrl.slice(0, -1) + selectedItem.icon}`
                            "
                            :achievementsTitle="selectedItem.name"
                            :achievementsDescription="selectedItem.description"
                            :achievementsRarity="selectedItem.howManyHaveAchievement"
                            :onClose="() => onCloseModal()"
                        />
                    </div>
                    <!-- <div class="dashboardAchievements__noContent">
                        <p class="h2 colorBotticelli">
                            Вы еще не получили ни одного достижения
                        </p>
                    </div> -->
                </div>

                <div class="dashboardAchievements">
                    <p class="h2 colorFiord">
                        Закрытые ({{ closedAchievementsList.length }})
                    </p>
                    <div class="dashboardAchievements__content">
                        <div
                            :class="
                                viewAll
                                    ? `dashboardAchievements__contentItem dashboardAchievements__contentItem--hidden`
                                    : `dashboardAchievements__contentItem`
                            "
                            v-for="item in closedAchievementsList"
                            :key="item.achievementsTitle"
                        >
                            <AchievementsCard
                                class="achievementsCard--closed"
                                :achievementsRarity="item.howManyHaveAchievement"
                                :achievementsTitle="item.name"
                                :achievementsDescription="item.description"
                            />
                        </div>
                        <div
                            :class="viewAll ? `dashboardAchievements__viewAll` : `hidden`"
                            @click="viewAll = !viewAll"
                        >
                            <p class="h3 colorGullGray">
                                Показать еще {{ closedAchievementsList.length - 2 }}
                                <br />
                                достижения
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import PageHeader from "@/views/partials/pageHeader/pageHeader";
import Button from "@/views/components/Button/Button";
import AchievementsCard from "@/views/components/AchievementsCard/AchievementsCard";
import DashboardAchievementsModal from "@/views/pages/dashboard/dashboardAchievements/dashboardAchievementsModal/dashboardAchievementsModal";

export default {
    name: "dashboardAchievements",
    components: {
        Layout,
        PageHeader,
        Button,
        AchievementsCard,
        DashboardAchievementsModal,
    },

    props: ["onClose"],

    async beforeMount() {
        this.updateDashboardAchievement();
    },

    watch: {
        "$route.params.slug": function() {
            this.updateDashboardAchievement();
        },
    },

    computed: {
        achievementsList() {
            const achievementsListItem = this.$store.getters.getDashboardAchievement
                .achievement.achievements;
            // for (const [index, item] of achievementsListItem.entries()) {
            //     if (item.isActive) {
            //         achievementsListItem[index].icon = this.achievements[index].icon;
            //     }
            // }

            return [...achievementsListItem.filter((item) => item.isActive)];
        },

        closedAchievementsList() {
            return this.$store.getters.getDashboardAchievement.achievement.achievements.filter(
                (item) => !item.isActive
            );
        },

        apiURL: function() {
            return process.env.VUE_APP_API_URL;
        },

        oldClientURL: function() {
            return process.env.VUE_APP_OLD_CLIENT_TEST_URL;
        },
    },

    data() {
        return {
            title: "Достижения",
            items: [
                {
                    text: "Личный дашборд",
                    to: `/${this.$route.params.slug}/dashboard`,
                },
                {
                    text: "Достижения",
                    active: true,
                },
            ],

            isVisibleModal: false,
            viewAll: true,
            selectedItem: [],
        };
    },
    methods: {
        onOpenModal(_id) {
            this.selectedItem = this.achievementsList.find((item) => item._id === _id);
            this.isVisibleModal = true;
        },

        onCloseModal() {
            this.isVisibleModal = false;
            this.onClose;
        },

        async updateDashboardAchievement() {
            const getDashboardAchievement = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/study/achievements"
            );

            if (
                getDashboardAchievement &&
                getDashboardAchievement.data &&
                getDashboardAchievement.data.result
            ) {
                await this.$store.dispatch(
                    "setDashboardAchievement",
                    getDashboardAchievement.data
                );
            } else {
                console.error(
                    "Ошибка при поиске достижений: " +
                        (getDashboardAchievement.data.message || "")
                );
                await this.$store.dispatch("clearDashboardAchievement");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "dashboardAchievements.scss";
</style>
